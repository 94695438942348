import React from 'react';

export interface StatsIconProps {
  className?: string;
}
export const StatsIcon: React.FC<StatsIconProps> = ({ className }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M2.25 16.5V6H5.25V16.5H2.25ZM7.5 16.5V1.5H10.5V16.5H7.5ZM12.75 16.5V10.5H15.75V16.5H12.75Z" />
  </svg>
);
