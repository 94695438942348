import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { Boost } from 'components/Boost';
import Loader from 'components/common/Loader';
import Friends from 'components/Friends';
import General from 'components/General';
import Mining from 'components/Mining';
import Stats from 'components/Stats';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import {
  boostTelegramCheck,
  boostTwitterCheck,
  getConfig,
  getUserInfo,
} from 'packets/api';
import React, { CSSProperties, useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { Bounce, Slide, ToastContainer } from 'react-toastify';

import { useAuth } from './providers/authContextProvider';
import { stopLoading } from './redux/errorSlice';
import {
  setConfig,
  setTelegramBoost,
  setTwitterBoost,
  setUserData,
} from './redux/userSlice';
import 'react-toastify/dist/ReactToastify.css';

export default function App() {
  const WebApp = useWebApp();
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector((state) => state.errorSlice.loading);
  useEffect(() => {
    WebApp.setHeaderColor('#0D0D0E');
  }, []);

  const navigate = useNavigate();
  useEffect(() => {
    if (!auth?.secretKey) {
      dispatch(stopLoading());
      return;
    }
    const fun = async () => {
      const config = getConfig(auth.secretKey);
      const userData = getUserInfo(auth.secretKey);
      const telegramBoost = boostTelegramCheck(auth.secretKey);

      await Promise.all([config, userData, telegramBoost]).then(
        ([config, userData, telegramBoost]) => {
          dispatch(setUserData(userData));
          dispatch(setTelegramBoost(telegramBoost));
          dispatch(
            setTwitterBoost({
              message: '',
              success: userData.bonuses?.some(
                (bonus) => bonus.name === 'TWITTER_SUB'
              ),
            })
          );
          dispatch(setConfig(config));
        }
      );
      if (auth.plan) {
        navigate('/mining');
      }
    };

    fun().finally(() => setTimeout(() => dispatch(stopLoading()), 50));
  }, [auth]);

  if (isLoading) {
    return <Loader forceLoading={true} />;
  }

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        limit={2}
        hideProgressBar
        toastClassName="!rounded-[5px] !overflow-hidden "
        closeOnClick
        style={
          {
            '--toastify-toast-min-height': '48px',
            '--toastify-color-warning': '#f1600f',
            '--toastify-color-info': '#5C3DE7',
          } as CSSProperties
        }
        bodyClassName="!py-0 !my-0 "
        className="flex flex-col gap-[8px] items-end !pt-[8px] !px-[8px]"
        draggable
        theme="colored"
        transition={Bounce}
      />
      <Routes>
        <Route
          path="/"
          element={<General />}
        />
        <Route
          path="/mining"
          element={<Mining />}
        />
        <Route
          path="/boost"
          element={<Boost />}
        />
        <Route
          path="/friends"
          element={<Friends />}
        />
        <Route
          path="/stats"
          element={<Stats />}
        />
      </Routes>
    </div>
  );
}
