import React from 'react';

export interface BoostIconProps {
  className?: string;
}

export const BoostIcon: React.FC<BoostIconProps> = ({ className }) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M9.66669 12C9.06995 12 8.49765 11.7629 8.0757 11.341C7.65374 10.919 7.41669 10.3467 7.41669 9.75C7.41669 8.91 7.87419 8.175 8.54169 7.7925L15.8242 3.5775L11.6767 10.7625C11.3017 11.4975 10.5442 12 9.66669 12ZM9.66669 2.25C11.0242 2.25 12.2917 2.625 13.3942 3.24L11.8192 4.1475C11.1667 3.8925 10.4167 3.75 9.66669 3.75C8.07539 3.75 6.54926 4.38214 5.42405 5.50736C4.29883 6.63258 3.66669 8.1587 3.66669 9.75C3.66669 11.4075 4.33419 12.9075 5.42169 13.9875H5.42919C5.72169 14.28 5.72169 14.7525 5.42919 15.045C5.13669 15.3375 4.65669 15.3375 4.36419 15.0525C3.00669 13.695 2.16669 11.82 2.16669 9.75C2.16669 7.76088 2.95686 5.85322 4.36339 4.4467C5.76991 3.04018 7.67756 2.25 9.66669 2.25ZM17.1667 9.75C17.1667 11.82 16.3267 13.695 14.9692 15.0525C14.6767 15.3375 14.2042 15.3375 13.9117 15.045C13.6192 14.7525 13.6192 14.28 13.9117 13.9875C14.9992 12.9 15.6667 11.4075 15.6667 9.75C15.6667 9 15.5242 8.25 15.2617 7.575L16.1692 6C16.7917 7.125 17.1667 8.385 17.1667 9.75Z" />
    </svg>
  );
};
