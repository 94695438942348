import { setError } from '../errorSlice';

export const errorMiddleware = (store) => (next) => (action) => {
  if (action.type.endsWith('rejected')) {
    const isServerError = action.error?.originalStatus === 500;
    if (isServerError) {
      store.dispatch(setError(action.error.message || 'Server error'));
    }
  }

  return next(action);
};
