import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { BonusCheckResponse, ConfigResponse, UserData } from 'types/api';

export interface userInitialData {
  userData: UserData | null;
  config: ConfigResponse | null;
  telegramBoost: BonusCheckResponse | null;
  twitterBoost: BonusCheckResponse | null;
  checkTwitterActive: boolean;
}

const initialState: userInitialData = {
  telegramBoost: null,
  twitterBoost: null,
  config: null,
  userData: null,
  checkTwitterActive: Cookies.get('checkTwitterActive') === 'true' || false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<UserData>) => {
      state.userData = { ...state.userData, ...action.payload };
    },
    setConfig: (state, action: PayloadAction<ConfigResponse>) => {
      state.config = action.payload;
    },
    setTelegramBoost: (state, action: PayloadAction<BonusCheckResponse>) => {
      state.telegramBoost = action.payload;
    },
    setTwitterBoost: (state, action: PayloadAction<BonusCheckResponse>) => {
      state.twitterBoost = action.payload;
    },
    setCheckTwitterActive: (state, action: PayloadAction<boolean>) => {
      Cookies.set('checkTwitterActive', String(action.payload));
      state.checkTwitterActive = action.payload;
    },
  },
});

export const {
  setUserData,
  setTwitterBoost,
  setTelegramBoost,
  setConfig,
  setCheckTwitterActive,
} = userSlice.actions;

export default userSlice.reducer;
