import { configureStore } from '@reduxjs/toolkit';

import claimSlice from './claimSlice';
import errorSlice from './errorSlice';
import { errorMiddleware } from './Middlwares/errorMiddleware';
import userSlice from './userSlice';

export const store = configureStore({
  reducer: {
    userSlice,
    errorSlice,
    claimSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(errorMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
