import React from 'react';

export interface CrossIconProps {
  className?: string;
}

export const CrossIcon: React.FC<CrossIconProps> = ({ className }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M15.8334 6.66663L13.3334 4.16663L10.0001 7.49996L6.66675 4.16663L4.16675 6.66663L7.50008 9.99996L4.16675 13.3333L6.66675 15.8333L10.0001 12.5L13.3334 15.8333L15.8334 13.3333L12.5001 9.99996L15.8334 6.66663Z"
      fill="#C32117"
    />
  </svg>
);
