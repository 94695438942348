import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { useAppSelector } from 'hooks/redux';
import { getReferralLink } from 'packets/api';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Banner } from './common/Banner';
import { Button } from './common/Button';
import { Footer } from './common/Footer';
import { useAuth } from '../providers/authContextProvider';

export default function Friends() {
  const userData = useAppSelector((state) => state.userSlice.userData);
  const auth = useAuth();
  const webApp = useWebApp();
  const config = useAppSelector((state) => state.userSlice.config);
  const handleInvite = useCallback(async () => {
    await getReferralLink(auth.secretKey);
    webApp.openTelegramLink(`https://t.me/${config.BOT_PARTNER_NAME}`);
    // webApp.close();
  }, [auth.secretKey]);

  const cashback = useMemo(
    () =>
      userData?.friends.reduce(
        (acc, cur) => (acc + cur.referClaimed) as number,
        0
      ),
    []
  );

  return (
    <div className="flex flex-col h-screen pt-[22px] px-[16px] pb-[16px] justify-between ">
      <div className="flex flex-col items-center justify-between gap-3.5">
        <Banner className="!h-[103px] w-full justify-center items-center bg-left-bottom mb-[10px]">
          <div className="flex flex-col items-center">
            <p className="font-normal text-[15px] text-[#FFFFFF] opacity-50">
              You invited
            </p>
            <div className="flex gap-3.5 items-center">
              <p className="font-bold text-[32px] text-[#FFFFFF]">
                {userData?.friends?.length}
              </p>
              <span
                className="text-[32px] text-transparent leading-[26px] font-bold tracking-[0.64px]"
                style={{
                  WebkitTextStrokeWidth: 'thin',
                  WebkitTextStrokeColor: 'white',
                }}
              >
                friends
              </span>
            </div>
          </div>
        </Banner>
        <div className="flex flex-col items-center mb-[18px]">
          <p className="text-[#9D9BA6] font-normal text-[15px]">
            Your cashback:
          </p>
          <div className="flex gap-2.5 text-[#FFFFFF] font-normal text-[21px]">
            <p>+{cashback.toFixed(2)}</p>
            <p>tits</p>
          </div>
        </div>
        <div>
          <p className="text-[#9D9BA6] font-normal text-center text-[15px]">
            Every time your friend claims TITS <br />
            you get 20% cashback.
          </p>
        </div>
        <Button onClick={handleInvite}>Invite Friend</Button>
      </div>
      <Footer className="mt-[14px]" />
    </div>
  );
}
