import classnames from 'classnames';
import { Button } from 'components/common/Button';
import { Table } from 'components/common/Table';
import React, { useCallback } from 'react';

import { Plan } from '../../../types/api';
import { BoostTableRow } from '../../../types/boost';
import { TableColumn } from '../../../types/table';

export interface PlanTableProps {
  className?: string;
}
export const PlanTable: React.FC<PlanTableProps> = ({ className }) => {
  const handleBuyPlan = useCallback(() => {
    open('https://nudify.online/plan');
  }, []);

  return (
    <div className={classnames('flex flex-col items-center', className)}>
      <div className="font-bold text-[18px] leading-[18px] text-white">
        Buy any tariff{' '}
        <span className="text-[#EF5837] font-bold text-[18px] leading-[18px]">
          on site
        </span>
      </div>
      <div className="mt-[14px] text-[13px] text-[#9D9BA6] leading-[18px] font-normal">
        Data in the table is shown in “tits/hour”
      </div>
      <Table
        className="mt-[14px]"
        rows={rows}
        cols={cols}
      />
      <Button
        size="small"
        className="!w-full mt-[28px]"
        onClick={handleBuyPlan}
      >
        Go to purchase
      </Button>
    </div>
  );
};

const rows: BoostTableRow[] = [
  {
    id: 2,
    plan: 'Basic',
    firstMonth: '+200',
    thirdMonth: '+600',
  },
  {
    id: 1,
    plan: 'Standard',
    firstMonth: '+420',
    thirdMonth: '+1260',
  },
  {
    id: 3,
    plan: 'Pro',
    firstMonth: '+1200',
    thirdMonth: '+3600',
  },
];

const cols: TableColumn<BoostTableRow>[] = [
  { key: 'plan', name: '', cellClass: '!text-left' },
  { key: 'firstMonth', name: '1 mo.', cellClass: 'font-extrabold' },
  { key: 'thirdMonth', name: '3 mo.', cellClass: 'font-extrabold' },
];
