import classnames from 'classnames';
import React from 'react';

export interface DividerProps {
  className?: string;
}

export const Divider: React.FC<DividerProps> = ({ className }) => {
  return (
    <div className={classnames('h-[1px] bg-[#272628] w-full', className)} />
  );
};
