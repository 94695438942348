import { WebAppProvider } from '@vkruglikov/react-telegram-web-app';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import './global.css';

import { BrowserRouter as Router } from 'react-router-dom';

import App from './App.js';
import { AuthContextProvider } from './providers/authContextProvider';
import { store } from './redux/store';

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <WebAppProvider options={{ smoothButtonsTransition: true }}>
      <Provider store={store}>
        <AuthContextProvider>
          <Router>
            <App />
          </Router>
        </AuthContextProvider>
      </Provider>
    </WebAppProvider>
  </React.StrictMode>
);
