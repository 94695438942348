import React from 'react';

export interface RenewIconProps {
  className?: string;
}

export const RenewIcon: React.FC<RenewIconProps> = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        width="20"
        height="20"
        rx="10"
        fill="#171717"
      />
      <path
        d="M9.99992 5.00004V7.50004L13.3333 4.16671L9.99992 0.833374V3.33337C8.23181 3.33337 6.53612 4.03575 5.28587 5.286C4.03563 6.53624 3.33325 8.23193 3.33325 10C3.33325 11.3084 3.71659 12.525 4.36659 13.55L5.58325 12.3334C5.20825 11.6417 4.99992 10.8334 4.99992 10C4.99992 8.67396 5.5267 7.40219 6.46438 6.46451C7.40207 5.52682 8.67384 5.00004 9.99992 5.00004ZM15.6333 6.45004L14.4166 7.66671C14.7833 8.36671 14.9999 9.16671 14.9999 10C14.9999 11.3261 14.4731 12.5979 13.5355 13.5356C12.5978 14.4733 11.326 15 9.99992 15V12.5L6.66659 15.8334L9.99992 19.1667V16.6667C11.768 16.6667 13.4637 15.9643 14.714 14.7141C15.9642 13.4638 16.6666 11.7681 16.6666 10C16.6666 8.69171 16.2833 7.47504 15.6333 6.45004Z"
        fill="white"
      />
    </svg>
  );
};
