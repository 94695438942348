import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import React, { createContext, useContext, useEffect, useState } from 'react';

import { useAppDispatch } from '../hooks/redux';
import { auth } from '../packets/api';
import { setError, startLoading, stopLoading } from '../redux/errorSlice';

interface AuthContextType {
  secretKey: string;
  generationsLeft: number;
  plan: string;
  balance: number;
  pendingBalance: number;
  status: string;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const WebApp = useWebApp();
  const dispatch = useAppDispatch();
  const [authContext, setAuthContext] = useState<AuthContextType | null>(null);

  useEffect(() => {
    dispatch(startLoading());
    auth(WebApp.initData).then((x) => {
      if (!x.success) {
        dispatch(setError('Unauthorized'));
        return;
      }
      setAuthContext({
        secretKey: x.secretKey,
        plan: x.plan,
        generationsLeft: x.generationsLeft,
        balance: 0,
        pendingBalance: 0,
        status: 'api',
      });
    });
  }, []);

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  );
};
