import classnames from 'classnames';
import { Button } from 'components/common/Button';
import { CheckIcon } from 'components/Icons/CheckIcon';
import { CrossIcon } from 'components/Icons/CrossIcon';
import { RenewIcon } from 'components/Icons/RenewIcon';
import { TelegramIcon } from 'components/Icons/TelegramIcon';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { boostTelegramCheck } from 'packets/api';
import React, { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

import { useAuth } from '../../../providers/authContextProvider';
import { setTelegramBoost } from '../../../redux/userSlice';

export interface SubscribeTelegramProps {
  className?: string;
}

export const SubscribeTelegram: React.FC<SubscribeTelegramProps> = ({
  className,
}) => {
  const [showSubscribed, setShowSubscribed] = useState(false);
  const boost = useAppSelector((state) => state.userSlice.telegramBoost);
  const config = useAppSelector((state) => state.userSlice.config);
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const handleClickSubscribe = useCallback(() => {
    open(config?.TELEGRAM);
  }, [config]);

  const handleCheck = useCallback(async () => {
    if (!auth?.secretKey) {
      return;
    }
    const boost = await boostTelegramCheck(auth.secretKey);
    setShowSubscribed(true);
    dispatch(setTelegramBoost(boost));
    if (!boost.success) {
      toast(boost.message, { type: 'info' });
    }
  }, [auth?.secretKey]);

  return (
    <div className={classnames('flex flex-col items-center w-full', className)}>
      <div className="text-[#9D9BA6] text-[18px] leading-[18px] font-normal">
        Subscribe to our blog
      </div>
      <div className="text-[#E7E6EC] text-center w-full border-solid border-[1px] border-[#272628] py-[18px] px-[10px] rounded-[16px] mt-[14px]">
        +{config?.TG_SUBSCRIBE_SPEED_BONUS} tits/hour until the end
      </div>
      {!boost?.success && (
        <>
          <Button
            variant="secondary"
            className="mt-[8px] !w-full"
            onClick={handleClickSubscribe}
          >
            <TelegramIcon className="mr-[6px] rounded-full" />
            <div className="pr-[10px] text-[16px] text-white leading-[23px] font-normal">
              Subscribe
            </div>
          </Button>
          <Button
            variant="clear"
            size="small"
            className="mt-[8px] !w-full"
            onClick={handleCheck}
          >
            <RenewIcon className="mr-[6px]" />
            <div className="pr-[10px] text-[16px] text-white leading-[23px] font-normal">
              Check
            </div>
          </Button>
        </>
      )}

      {(boost.success || showSubscribed) && (
        <Button
          variant="clear"
          size="small"
          className={classnames(
            '!w-full mt-[8px] text-[16px] leading-[23px] font-normal',
            {
              '!bg-[rgba(1,235,114,0.10)] !text-[#01EB72]': boost?.success,
              '!bg-[rgba(195,33,23,0.10)] !text-[#C32117]': !boost?.success,
            }
          )}
        >
          {boost.success ? (
            <CheckIcon className="mr-[8px]" />
          ) : (
            <CrossIcon className="mr-[8px]" />
          )}
          {boost.success ? 'You are subscribed!' : 'You are not subscribed'}
        </Button>
      )}
    </div>
  );
};
