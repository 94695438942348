import { MainButton, useShowPopup } from '@vkruglikov/react-telegram-web-app';
import { Balance } from 'components/common/Balance';
import { Banner } from 'components/common/Banner';
import { ClaimTimer } from 'components/common/ClaimTimer';
import { Footer } from 'components/common/Footer';
import WebAppFooter from 'components/WebAppFooter';
import { useAppSelector } from 'hooks/redux';
import { useClaim } from 'hooks/timer';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../providers/authContextProvider';

export default function Mining() {
  const userData = useAppSelector((state) => state.userSlice.userData);
  const pendingBalance = useAppSelector(
    (state) => state.claimSlice.pendingBalance
  );

  return (
    <div className="flex flex-col h-screen pt-[22px] px-[16px] pb-[16px] justify-between ">
      <div className="flex flex-col items-center justify-between mb-[32px]">
        <Banner className="w-full h-[244px] items-center justify-center">
          <div className="text-[15px] text-white leading-[18px] font-normal opacity-[0.5] text-center">
            In the storage:
          </div>
          <div className="text-[32px] text-white leading-[36px] font-bold">
            {pendingBalance}{' '}
            <span
              className="stroke-2 ext-[32px] text-transparent leading-[36px] font-bold"
              style={{
                WebkitTextStrokeWidth: 'thin',
                WebkitTextStrokeColor: 'white',
              }}
            >
              TITS
            </span>
          </div>
          <div className="text-[10px] text-white leading-[14px] font-normal opacity-[0.5] text-center">
            {userData?.speed || 0} tits/hour
          </div>
          <Link
            to="/boost"
            className="absolute bottom-[14px] text-[10px] text-white leading-[14px] font-normal left-[50%] translate-x-[-50%]"
          >
            How can I increase the speed?
          </Link>
        </Banner>
        <ClaimTimer className="mt-[14px]" />
        <Balance className="mt-[14px]" />
      </div>
      <Footer className="mt-[14px]" />
    </div>
  );
}
