import React from 'react';

export interface FriendsIconProps {
  className?: string;
}
export const FriendsIcon: React.FC<FriendsIconProps> = ({ className }) => (
  <svg
    width="19"
    height="18"
    viewBox="0 0 19 18"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_44_127)">
      <path d="M9.33337 4.125C10.0296 4.125 10.6972 4.40156 11.1895 4.89384C11.6818 5.38613 11.9584 6.05381 11.9584 6.75C11.9584 7.44619 11.6818 8.11387 11.1895 8.60616C10.6972 9.09844 10.0296 9.375 9.33337 9.375C8.63718 9.375 7.9695 9.09844 7.47722 8.60616C6.98494 8.11387 6.70837 7.44619 6.70837 6.75C6.70837 6.05381 6.98494 5.38613 7.47722 4.89384C7.9695 4.40156 8.63718 4.125 9.33337 4.125ZM4.08337 6C4.50337 6 4.89337 6.1125 5.23087 6.315C5.11837 7.3875 5.43337 8.4525 6.07837 9.285C5.70337 10.005 4.95337 10.5 4.08337 10.5C3.48664 10.5 2.91434 10.2629 2.49238 9.84099C2.07043 9.41903 1.83337 8.84674 1.83337 8.25C1.83337 7.65326 2.07043 7.08097 2.49238 6.65901C2.91434 6.23705 3.48664 6 4.08337 6ZM14.5834 6C15.1801 6 15.7524 6.23705 16.1744 6.65901C16.5963 7.08097 16.8334 7.65326 16.8334 8.25C16.8334 8.84674 16.5963 9.41903 16.1744 9.84099C15.7524 10.2629 15.1801 10.5 14.5834 10.5C13.7134 10.5 12.9634 10.005 12.5884 9.285C13.2334 8.4525 13.5484 7.3875 13.4359 6.315C13.7734 6.1125 14.1634 6 14.5834 6ZM4.45837 13.6875C4.45837 12.135 6.64087 10.875 9.33337 10.875C12.0259 10.875 14.2084 12.135 14.2084 13.6875V15H4.45837V13.6875ZM0.333374 15V13.875C0.333374 12.8325 1.75087 11.955 3.67087 11.7C3.22837 12.21 2.95837 12.915 2.95837 13.6875V15H0.333374ZM18.3334 15H15.7084V13.6875C15.7084 12.915 15.4384 12.21 14.9959 11.7C16.9159 11.955 18.3334 12.8325 18.3334 13.875V15Z" />
    </g>
    <defs>
      <clipPath id="clip0_44_127">
        <rect
          width="18"
          height="18"
          transform="translate(0.333374)"
        />
      </clipPath>
    </defs>
  </svg>
);
