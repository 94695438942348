import config from 'tailwindcss/defaultConfig';
import { ConfigResponse } from 'types/api';

import { HOURS6 } from '../constants/timer';

export const getFarmingTime = (lastClaimTime: Date) => {
  const now = new Date().getTime();
  const lastClaim = lastClaimTime.getTime();
  return Math.min(now - lastClaim, HOURS6);
};

export const getPendingBalance = (
  timeLast: number,
  speed: number,
  config: ConfigResponse
): number => {
  return Math.max(
    0,
    Math.floor(
      ((timeLast * (config.BASE_SPEED + speed)) / config.HOUR1) * 1000
    ) / 1000
  );
};
