import classnames from 'classnames';
import { ShieldIcon } from 'components/Icons/ShieldIcon';
import { useAppSelector } from 'hooks/redux';
import React from 'react';

import { useAuth } from '../../../providers/authContextProvider';

export interface Balance {
  className?: string;
}

export const Balance: React.FC<Balance> = ({ className }) => {
  const userData = useAppSelector((state) => state.userSlice.userData);

  return (
    <div
      className={classnames(
        'flex gap-[14px] p-[14px] rounded-[24px] border-solid border-[1px] border-[#272628] w-full items-center',
        className
      )}
    >
      <div className="rounded-[14px] bg-gradient-to-b from-[#5C3DE7] to-[#361DA6] p-[13px]">
        <ShieldIcon />
      </div>
      <div className="flex flex-col gap-[6px]">
        <div className="text-[15px] leading-[18px] font-normal text-[#9D9BA6]">
          Your balance:
        </div>
        <div className="text-[21px] leading-[21px] font-normal tracking-[0.42px] text-white">
          {userData.balance.toFixed(4)} TITS
        </div>
      </div>
    </div>
  );
};
