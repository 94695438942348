import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { farming } from 'packets/api';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getFarmingTime, getPendingBalance } from 'utils/user';

import { HOUR, HOURS6, MINUTE, SECOND } from '../constants/timer';
import { useAuth } from '../providers/authContextProvider';
import { setPendingBalance, setTime } from '../redux/claimSlice';
import { setUserData } from '../redux/userSlice';

export const useClaim = () => {
  const auth = useAuth();
  const dispatch = useAppDispatch();
  const userData = useAppSelector((state) => state.userSlice.userData);
  const config = useAppSelector((state) => state.userSlice.config);

  useEffect(() => {
    if (!userData?.lastClaim) {
      return;
    }
    const func = () => {
      const lastClaim = dayjs(userData.lastClaim).toDate();
      const timeLast = getFarmingTime(lastClaim);
      const time = HOURS6 - timeLast;
      const hours = Math.floor(time / HOUR);
      const minutes = Math.floor((time - hours * HOUR) / MINUTE);
      const seconds = Math.floor(
        (time - hours * HOUR - minutes * MINUTE) / SECOND
      );
      dispatch(
        setTime(
          `${hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`
        )
      );
      const pending = getPendingBalance(timeLast, userData.speed, config);
      dispatch(setPendingBalance(pending));
    };
    func();
    const interval = setInterval(func, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [userData?.lastClaim]);

  const claim = useCallback(async () => {
    if (!auth?.secretKey) {
      toast('You are not authorized', { type: 'error' });
      return;
    }
    const res = await farming(auth?.secretKey);
    if (res.success) {
      dispatch(setTime('6:00:00'));
      dispatch(setPendingBalance(0));
      dispatch(setUserData(res.data));
    } else if (res.success === false) {
      toast(res.message, {
        type: 'warning',
      });
    }
  }, [auth?.secretKey]);

  return claim;
};
