import classnames from 'classnames';
import React from 'react';

export interface BannerProps {
  className?: string;
  children?: React.ReactNode;
  img?: string;
}
export const Banner: React.FC<BannerProps> = ({ className, children, img }) => {
  return (
    <div
      className={classnames(
        "h-[236px] flex flex-col justify-end items-center relative bg-[url('/img/gradient.png')] bg-cover bg-center rounded-[32px] overflow-hidden",
        className
      )}
    >
      {img && (
        <img
          className="absolute min-w-full min-h-full object-cover"
          src={img}
        />
      )}
      <div className="z-10">{children}</div>
    </div>
  );
};
