import { useAppSelector } from 'hooks/redux';
import React from 'react';

import './index.css';

type TLoaderProps = {
  forceLoading?: boolean;
};

const Loader = ({ forceLoading }: TLoaderProps) => {
  const { loading } = useAppSelector((state) => state.errorSlice);

  if (!forceLoading && !loading) return null;

  return (
    <div className="loader-slice">
      <div className="loader-spinner"></div>
      <div>Loading...</div>
    </div>
  );
};

export default Loader;
