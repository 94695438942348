import React from 'react';

export interface LogoIconProps {
  className?: string;
}
export const LogoIcon: React.FC<LogoIconProps> = ({ className }) => (
  <svg
    width="37"
    height="26"
    viewBox="0 0 37 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0_25_503)">
      <mask
        id="mask0_25_503"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="37"
        height="26"
      >
        <path
          d="M36.9999 0H0V25.9613H36.9999V0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_25_503)">
        <mask
          id="mask1_25_503"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="37"
          height="26"
        >
          <path
            d="M37 0H0V25.9613H37V0Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_25_503)">
          <path
            d="M12.2384 25C6.08766 25 1 19.6851 1 13C1 6.31489 6.08766 1 12.2384 1H24.7614C30.9122 1 36 6.31491 36 13C36 19.6851 30.9122 25 24.7614 25H12.2384Z"
            stroke="#EF5837"
            strokeWidth="2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.0681 6.33245C14.2545 6.40282 14.496 6.5928 14.6051 6.75464C14.7891 7.02747 14.8035 7.19945 14.8038 9.11614L14.8042 10.8586C14.8042 11.0635 14.9035 11.2569 15.0734 11.3828C16.0929 12.1382 17.8272 13.813 18.5277 14.7184C18.6411 14.865 18.8702 14.8682 18.988 14.7247L19.263 14.3898C20.1399 13.3221 21.1633 12.2957 21.9291 11.7157C22.395 11.3628 22.6665 10.8271 22.6665 10.2604V9.16691C22.6665 6.97287 22.7164 6.72987 23.2356 6.39545C23.6383 6.13609 24.3482 6.13609 24.7509 6.39545C25.2702 6.72987 25.32 6.97271 25.32 9.16931V10.214C25.32 10.8195 25.5804 11.3986 26.0406 11.8166C27.3141 12.9733 27.8753 14.2218 27.8753 15.8981C27.8753 18.2108 26.5316 19.609 24.1495 19.7753C23.2968 19.8347 21.552 19.6011 19.7785 19.1899L19.7523 19.1839C19.0667 19.0249 18.3509 19.0254 17.6655 19.1854C16.3609 19.4898 14.3225 19.8054 13.674 19.8033C12.9995 19.8011 12.0197 19.58 11.5279 19.3189C10.1741 18.6003 9.50533 17.2529 9.62915 15.4932C9.7353 13.9852 10.2937 12.8725 11.4852 11.7948C11.9729 11.3538 12.249 10.7415 12.249 10.1012V9.087C12.249 7.22291 12.2639 7.04798 12.4461 6.78131C12.6575 6.47198 13.1405 6.20978 13.5052 6.20646C13.6285 6.20531 13.8819 6.26203 14.0681 6.33245ZM13.2168 13.5599C12.5166 14.2493 12.241 14.9116 12.2386 15.9108C12.2358 17.041 12.6004 17.4044 13.737 17.4044C14.2404 17.4044 16.8379 17.0025 16.9561 16.9063C17.0091 16.8633 16.5325 16.2136 16.0503 15.671C15.6141 15.1804 13.6125 13.3155 13.5221 13.3155C13.4907 13.3155 13.3533 13.4255 13.2168 13.5599ZM22.8503 14.271C21.8393 15.2119 20.847 16.2778 20.6145 16.6724C20.4785 16.9031 20.4472 16.8854 21.3397 17.0828C23.1156 17.4753 24.4526 17.4989 24.8691 17.1451C25.3869 16.7051 25.4388 15.392 24.9743 14.4852C24.706 13.9613 24.1638 13.3155 23.9924 13.3155C23.9289 13.3155 23.415 13.7455 22.8503 14.271Z"
            fill="white"
          />
        </g>
      </g>
    </g>
  </svg>
);
