import { Banner } from 'components/common/Banner';
import { Button } from 'components/common/Button';
import { Logo } from 'components/common/Logo';
import { getUserInfo } from 'packets/api';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LINK_HOST } from '../config';
import { useAuth } from '../providers/authContextProvider';

export default function General() {
  const auth = useAuth();
  const navigate = useNavigate();

  const handleClick = async () => {
    const link = document.createElement('a');
    link.target = '_blank';
    link.rel = 'nofollow';
    link.href = `${LINK_HOST}/nudx?auth=${auth.secretKey}`;
    link.click();

    let interval: ReturnType<typeof setInterval> | null = null;

    const checkLinked = async () => {
      const userData = await getUserInfo(auth.secretKey);
      if (userData.plan) {
        navigate('/mining');
        clearInterval(interval);
      }
    };
    interval = setInterval(checkLinked, 1000);
  };

  return (
    <div className="flex flex-col bg-[#0D0D0E] h-screen pt-[22px] px-[16px] pb-[16px] items-center">
      <Banner
        img="/img/img.jpeg"
        className="w-full"
      >
        <Logo className="z-10 mb-[14px] bg-[rgba(13,13,14,0.80)] pl-[8px] pr-[16px] py-[8px] rounded-[60px] " />
      </Banner>
      <p className="text-[16px] text-[#9D9BA6] text-center leading-[26px] font-normal mt-[14px]">
        Start mining the AI token of the photo processing product
      </p>
      <h2 className="text-[28px] text-white text-center leading-[36px] mt-[24px]">
        How to begin?
      </h2>
      <p className="text-center text-[#9D9BA6] text-[16px] leading-[26px] font-normal">
        Just register on nudify.online
      </p>
      <Button
        variant="primary"
        className="mt-[24px]"
        onClick={handleClick}
      >
        Link accounts
      </Button>
    </div>
  );
}
