import React from 'react';

export interface TelegramIconProps {
  className?: string;
}
export const TelegramIcon: React.FC<TelegramIconProps> = ({ className }) => {
  return (
    <svg
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.57251 12.503L19.0621 7.64286C19.6418 7.4315 20.1481 7.78558 19.9603 8.67024L19.9613 8.66915L17.8348 18.7806C17.6772 19.4975 17.2551 19.6718 16.6646 19.334L13.4261 16.9252L11.8641 18.4439C11.6914 18.6183 11.5457 18.7653 11.211 18.7653L11.441 15.4392L17.4429 9.96671C17.7041 9.73466 17.3846 9.60392 17.0403 9.83489L9.62313 14.548L6.4257 13.5413C5.73159 13.319 5.71648 12.8408 6.57251 12.503Z"
        fill="white"
      />
    </svg>
  );
};
