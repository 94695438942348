import { News } from 'components/Boost/News';
import { PlanTable } from 'components/Boost/PlanTable';
import { SubscribeX } from 'components/Boost/SubscribeX';
import { Banner } from 'components/common/Banner';
import { Divider } from 'components/common/Divider';
import { Footer } from 'components/common/Footer';
import React from 'react';

import { SubscribeTelegram } from './SubscribeTelegram';

export const Boost: React.FC = () => {
  return (
    <div className="flex flex-col h-screen  justify-between">
      <div className="flex flex-col items-center justify-between pb-[16px]">
        <div className="flex flex-col pt-[22px] px-[16px] w-full">
          <Banner className="!h-[104px] w-full justify-center items-center bg-left-bottom">
            <div className="text-[24px] leading-[30px] font-bold tracking-[-0.48px] text-white max-w-[260px] text-center">
              Boost your TITS mining speed!
            </div>
          </Banner>
          <SubscribeTelegram className="mt-[24px]" />
          <SubscribeX className="mt-[24px]" />
        </div>
        <Divider className="mt-[24px]" />
        <div className="flex flex-col px-[16px] w-full mt-[24px]">
          <News />
        </div>
        <Divider className="mt-[24px]" />
        <div className="flex flex-col px-[16px] w-full mt-[24px] mb-[40px]">
          <PlanTable />
        </div>
        <Footer />
      </div>
    </div>
  );
};
