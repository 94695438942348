import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { useAppSelector } from 'hooks/redux';
import { useClaim } from 'hooks/timer';
import { getReferralLink } from 'packets/api';
import React, { useCallback } from 'react';
import { StatsTableRow } from 'types/stats';
import { TableColumn } from 'types/table';

import { Banner } from './common/Banner';
import { Button } from './common/Button';
import { Footer } from './common/Footer';
import { Table } from './common/Table';
import { useAuth } from '../providers/authContextProvider';

export default function Stats() {
  const userData = useAppSelector((state) => state.userSlice.userData);
  const pendingBalance = useAppSelector(
    (state) => state.claimSlice.pendingBalance
  );
  const auth = useAuth();
  const config = useAppSelector((state) => state.userSlice.config);

  const webApp = useWebApp();
  useClaim();

  const handleInvite = useCallback(async () => {
    await getReferralLink(auth.secretKey);
    webApp.openTelegramLink(`https://t.me/${config.BOT_PARTNER_NAME}`);
  }, [auth.secretKey]);

  const rows: StatsTableRow[] = [
    {
      id: 1,
      invited: `${userData?.friends?.length} friends`,
      extract: '0',
      benifit: '0',
    },
  ];

  return (
    <div className="flex flex-col h-screen pt-[22px] px-[16px] pb-[16px] justify-between">
      <div className="flex flex-col items-center justify-between gap-3.5">
        <Banner className="!h-[103px] w-full justify-center items-center bg-left-bottom mb-[10px]">
          <div className="flex flex-col items-center">
            <p className="font-normal text-[15px] text-[#FFFFFF] opacity-50">
              Your balance:
            </p>
            <div className="flex gap-3.5 items-center">
              <p className="font-bold text-[32px] text-[#FFFFFF]">
                {userData?.balance.toFixed(4)}
              </p>
              <span
                className="text-[32px] text-transparent leading-[26px] font-bold"
                style={{
                  WebkitTextStrokeWidth: 'thin',
                  WebkitTextStrokeColor: 'white',
                }}
              >
                TITS
              </span>
            </div>
            <div className="flex gap-1.5 text-[#FFFFFF] font-normal text-[10px]">
              <p className="opacity-50">in storage:</p>
              <p>{pendingBalance.toFixed(4)} tits</p>
            </div>
          </div>
        </Banner>
        <div className="flex flex-col items-center w-full">
          <h2 className="text-[#FFFFFF] font-normal text-[18px]">
            Income from referrals
          </h2>
          <div className="flex flex-col items-center w-full">
            <Table
              className="mt-[14px] "
              rows={rows}
              cols={cols}
            />
          </div>
        </div>
        <Button onClick={handleInvite}>Invite more referrals</Button>
      </div>
      <Footer className="mt-[14px]" />
    </div>
  );
}

const cols: TableColumn<StatsTableRow>[] = [
  {
    key: 'invited',
    name: 'Invited',
    cellClass: '!text-left pr-[90px]',
    headerClass: '!text-left',
  },
  { key: 'extract', name: 'Extract', cellClass: 'font-extrabold' },
  { key: 'benifit', name: 'Benifit', cellClass: 'font-extrabold' },
];
