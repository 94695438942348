import React from 'react';

export interface CheckIconProps {
  className?: string;
}

export const CheckIcon: React.FC<CheckIconProps> = ({ className }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M17.0834 7.5L8.75008 15.8334L2.91675 10L5.41675 7.5L8.75008 10.8333L14.5834 5L17.0834 7.5Z"
        fill="#01EB72"
      />
    </svg>
  );
};
