import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import classnames from 'classnames';
import { Button } from 'components/common/Button';
import { LinkIcon } from 'components/Icons/LinkIcon';
import { useAppSelector } from 'hooks/redux';
import { getNewBonus } from 'packets/api';
import React, { useCallback } from 'react';
import { toast } from 'react-toastify';

import { useAuth } from '../../../providers/authContextProvider';

export interface NewsProps {
  className?: string;
}

export const News: React.FC<NewsProps> = ({ className }) => {
  const auth = useAuth();
  const config = useAppSelector((state) => state.userSlice.config);
  const WebApp = useWebApp();
  const handleClickNews = useCallback(async () => {
    if (!auth?.secretKey) {
      return;
    }
    const data = await getNewBonus(auth.secretKey);
    if (data.success) {
      WebApp.openTelegramLink('https://t.me/NudX_AI');
    } else {
      toast(data.message, { type: 'info' });
    }
  }, []);

  return (
    <div className={classnames('flex flex-col items-center w-full', className)}>
      <div className="text-[#9D9BA6] text-[18px] leading-[18px] font-normal">
        Read the news
      </div>
      <div className="text-[#E7E6EC] text-center w-full border-solid border-[1px] border-[#272628] py-[18px] px-[10px] rounded-[16px] mt-[14px]">
        +{config.NEWS_VIEWING_SPEED_BONUS} tits/hour for 1 day
      </div>
      <Button
        variant="secondary"
        className="mt-[8px] !w-full"
        onClick={handleClickNews}
      >
        <LinkIcon className="mr-[6px] rounded-full" />
        <div className="pr-[10px] text-[16px] text-white leading-[23px] font-normal">
          Read the news
        </div>
      </Button>
    </div>
  );
};
