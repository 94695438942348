import classnames from 'classnames';
import { BoostIcon } from 'components/Icons/BoostIcon';
import { FriendsIcon } from 'components/Icons/FriendsIcon';
import { MineIcon } from 'components/Icons/MineIcon';
import { StatsIcon } from 'components/Icons/StatsIcon';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export interface FooterProps {
  className?: string;
}
export const Footer: React.FC<FooterProps> = ({ className }) => {
  const { pathname } = useLocation();

  return (
    <div
      className={classnames(
        'w-full px-[32px] py-[16px] bg-black rounded-[16px] flex justify-between sticky bottom-[16px]',
        className
      )}
    >
      <Link
        to="/mining"
        className="flex text-[13px] leading-[18px] font-normal gap-[5px] text-[#EF5837]"
      >
        <MineIcon className={pathname === '/mining' ? 'fill-[#EF5837]' : ''} />
        {pathname === '/mining' && 'Mining'}
      </Link>
      <Link
        to="/boost"
        className="flex text-[13px] leading-[18px] font-normal gap-[5px] text-[#EF5837]"
      >
        <BoostIcon className={pathname === '/boost' ? 'fill-[#EF5837]' : ''} />
        {pathname === '/boost' && 'Boost'}
      </Link>
      <Link
        to="/friends"
        className="flex text-[13px] leading-[18px] font-normal gap-[5px] text-[#EF5837]"
      >
        <FriendsIcon
          className={pathname === '/friends' ? 'fill-[#EF5837]' : ''}
        />
        {pathname === '/friends' && 'Friends'}
      </Link>
      <Link
        to="/stats"
        className="flex text-[13px] leading-[18px] font-normal gap-[5px] text-[#EF5837]"
      >
        <StatsIcon className={pathname === '/stats' ? 'fill-[#EF5837]' : ''} />
        {pathname === '/stats' && 'Stats'}
      </Link>
    </div>
  );
};
