import classnames from 'classnames';
import { Button } from 'components/common/Button';
import dayjs from 'dayjs';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { useClaim } from 'hooks/timer';
import React, { useCallback, useEffect, useState } from 'react';
import { getFarmingTime } from 'utils/user';

import { HOURS6 } from '../../../constants/timer';
import {
  boostTelegramCheck,
  farming,
  getConfig,
  getUserInfo,
} from '../../../packets/api';
import { useAuth } from '../../../providers/authContextProvider';
import { setUserData } from '../../../redux/userSlice';

export interface ClaimTimerProps {
  className?: string;
}

export const ClaimTimer: React.FC<ClaimTimerProps> = ({ className }) => {
  const claim = useClaim();
  const time = useAppSelector((state) => state.claimSlice.time);
  const userData = useAppSelector((state) => state.userSlice.userData);
  return (
    <div className={classnames('pb-[25px] relative w-full', className)}>
      <div className="flex flex-col border-solid border-[1px] border-[#272628] rounded-[24px] pt-[14px] px-[32px] pb-[35px] w-full items-center">
        <div className="text-[10px] leading-[14px] text-[#C8C7CE] font-normal">
          The storage will fill up after:
        </div>
        <div className="text-white text-[21px] leading-[26px] tracking-[4.2px] font-bold">
          {time}
        </div>
      </div>
      <Button
        variant="primary"
        size="small"
        onClick={claim}
        className="absolute bottom-0 left-[50%] translate-x-[-50%]"
      >
        {userData.lastClaim ? 'Claim' : 'Start mining'}
      </Button>
    </div>
  );
};
