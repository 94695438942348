import classnames from 'classnames';
import { Divider } from 'components/common/Divider';
import React, { JSX } from 'react';

import { DefaultRow, TableColumn } from '../../../types/table';

export interface TableProps<Row extends DefaultRow> {
  className?: string;
  rows: Row[];
  cols: TableColumn<Row>[];
  rowClass?: string;
  headerClass?: string;
}

export function Table<Row extends DefaultRow>({
  className,
  rows,
  cols,
  headerClass,
  rowClass,
}: TableProps<Row>): JSX.Element {
  return (
    <div
      className={classnames(
        'w-full border-solid border-[1px] border-[#272628] rounded-[12px] overflow-hidden',
        className
      )}
    >
      <table className="w-full">
        <thead className="border-solid border-b-[1px] border-[#272628]">
          <tr className={classnames('h-[30px]', headerClass)}>
            {cols.map((col) => (
              <th
                className={classnames(
                  'text-[#434247] text-right px-[14px] text-[10px] font-normal leading-[18px]',
                  col.headerClass
                )}
                key={col.name}
              >
                {col.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <React.Fragment key={index}>
              {!!index && (
                <tr className="">
                  <td
                    colSpan={cols.length}
                    className="px-[14px] py-0"
                  >
                    <Divider />
                  </td>
                </tr>
              )}
              <tr className={classnames('h-[30px]', rowClass)}>
                {cols.map((col, index) => (
                  <td key={col.name}>
                    <div
                      className={classnames(
                        'text-white text-right px-[14px] text-[13px] font-normal leading-[18px] border-solid border-[#272628]',
                        { 'border-l-[1px]': index },
                        col.cellClass
                      )}
                    >
                      {row[col.key]}
                    </div>
                  </td>
                ))}
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
