import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { START_TIME } from '../constants/timer';

export const claimSlice = createSlice({
  name: 'error',
  initialState: {
    time: START_TIME,
    pendingBalance: 0,
  },
  reducers: {
    setTime: (state, action: PayloadAction<string>) => {
      state.time = action.payload;
    },
    setPendingBalance: (state, action: PayloadAction<number>) => {
      state.pendingBalance = action.payload;
    },
  },
});

export const { setTime, setPendingBalance } = claimSlice.actions;

export default claimSlice.reducer;
