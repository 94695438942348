import React from 'react';

export interface MineIconProps {
  className?: string;
}
export const MineIcon: React.FC<MineIconProps> = ({ className }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path d="M16.2432 2.54178C16.5857 2.88487 16.5856 3.44233 16.2432 3.78475L15.5045 4.52343C16.5055 6.75256 16.4729 10.2574 16.4709 10.412C16.4683 10.613 16.3296 10.7866 16.1342 10.8336C15.9394 10.8805 15.7366 10.7897 15.6426 10.6111C15.0232 9.43427 13.3809 6.5022 12.4392 5.56051C11.4757 4.60564 8.56 2.97505 7.38884 2.35714C7.21096 2.26347 7.11935 2.06105 7.16634 1.86557C7.21334 1.67012 7.38699 1.53147 7.58798 1.52887C7.74259 1.52711 11.2479 1.49424 13.4769 2.49528L14.2153 1.7569C14.5577 1.41448 15.1152 1.41434 15.458 1.75663L16.2432 2.54178Z" />
    <path d="M10.6706 5.3016C11.0178 5.53415 11.5231 5.88749 11.8176 6.18206C12.063 6.42738 12.3708 6.84025 12.6984 7.32954L3.78471 16.2431C3.44221 16.5856 2.88468 16.5856 2.542 16.2434L1.75685 15.4582C1.41434 15.1151 1.41443 14.5577 1.75685 14.2153L10.6706 5.3016Z" />
    <path d="M14.8974 12.5503L14.3423 10.7459C14.2552 10.4627 13.917 10.3443 13.6721 10.5138L11.4806 12.031L12.6438 13.1942L14.8974 12.5503Z" />
    <path d="M12.5326 14.1281C12.5853 14.1315 12.6396 14.1095 16.2611 13.0748L16.4701 16.0004C16.4883 16.2548 16.2865 16.4711 16.0317 16.4711H8.29681C8.16329 16.4711 8.03705 16.4104 7.95363 16.3062L7.01607 15.1342C6.87227 14.9545 6.89151 14.6943 7.06019 14.5376L10.0075 11.8009L12.2051 13.9985C12.2887 14.0821 12.4009 14.1273 12.5159 14.1273C12.5232 14.1273 12.5279 14.1278 12.5326 14.1281Z" />
  </svg>
);
