import React from 'react';

export interface ShieldIconProps {
  className?: string;
}

export const ShieldIcon: React.FC<ShieldIconProps> = ({ className }) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M26.25 13.75C26.25 20.6875 21.45 27.175 15 28.75C8.55 27.175 3.75 20.6875 3.75 13.75V6.25L15 1.25L26.25 6.25V13.75ZM15 26.25C19.6875 25 23.75 19.425 23.75 14.025V7.875L15 3.975L6.25 7.875V14.025C6.25 19.425 10.3125 25 15 26.25ZM18.5 13.75V11.875C18.5 10.125 16.75 8.75 15 8.75C13.25 8.75 11.5 10.125 11.5 11.875V13.75C10.75 13.75 10 14.5 10 15.25V19.625C10 20.5 10.75 21.25 11.5 21.25H18.375C19.25 21.25 20 20.5 20 19.75V15.375C20 14.5 19.25 13.75 18.5 13.75ZM16.875 13.75H13.125V11.875C13.125 10.875 14 10.25 15 10.25C16 10.25 16.875 10.875 16.875 11.875V13.75Z"
      fill="white"
    />
  </svg>
);
