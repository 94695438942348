import classnames from 'classnames';
import { LogoIcon } from 'components/Icons/LogoIcon';
import React from 'react';

export interface LogoProps {
  className?: string;
  fontSize?: number;
}

export const Logo: React.FC<LogoProps> = ({ className, fontSize = 16 }) => {
  return (
    <div className={classnames('gap-[24px] flex', className)}>
      <div className="gap-[10px] self-stretch flex items-center">
        <LogoIcon />
        <div className={`text-[${fontSize}px] text-white`}>nudify.online</div>
      </div>
    </div>
  );
};
