import React from 'react';

export interface XIconProps {
  className?: string;
}

export const XIcon: React.FC<XIconProps> = ({ className }) => {
  return (
    <svg
      width="27"
      height="26"
      viewBox="0 0 27 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="0.5"
        width="26"
        height="26"
        rx="6"
        fill="#171717"
      />
      <path
        d="M13.6416 11.0812L18.1089 6H17.0503L13.1714 10.4119L10.0733 6H6.5L11.1849 12.6716L6.5 18H7.55866L11.6549 13.3409L14.9267 18H18.5L13.6414 11.0812H13.6416ZM12.1916 12.7304L11.717 12.066L7.94011 6.7798H9.56615L12.6141 11.046L13.0888 11.7103L17.0508 17.2557H15.4248L12.1916 12.7306V12.7304Z"
        fill="white"
      />
    </svg>
  );
};
