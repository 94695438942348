import axios from 'axios';
import {
  AuthResponse,
  BonusCheckResponse,
  ConfigResponse,
  FarmingResponse,
  UserData,
} from 'types/api';

import { API_HOST } from '../../config';

export const auth = async (initData: string): Promise<AuthResponse> => {
  try {
    const res = await axios.post<AuthResponse>(
      `${API_HOST}/api/v1/auth`,
      initData
    );
    return res.data;
  } catch (e) {
    console.error(e);
  }
};
export const getUserInfo = async (secretKey: string) => {
  try {
    const response = await axios.get<UserData>(`${API_HOST}/api/v1/info`, {
      headers: { Authorization: secretKey },
    });
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const farming = async (secret: string) => {
  try {
    const response = await axios.post<FarmingResponse>(
      `${API_HOST}/api/v1/farming`,
      {},
      {
        headers: { Authorization: secret },
      }
    );
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const boostTelegramCheck = async (secret: string) => {
  try {
    const response = await axios.post<BonusCheckResponse>(
      `${API_HOST}/api/v1/boost/telegram/check`,
      {},
      {
        headers: { Authorization: secret },
      }
    );
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const boostTwitterCheck = async (secret: string) => {
  try {
    const response = await axios.post<BonusCheckResponse>(
      `${API_HOST}/api/v1/boost/twitter/check`,
      {},
      {
        headers: { Authorization: secret },
      }
    );
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const getConfig = async (secret: string) => {
  try {
    const response = await axios.get<ConfigResponse>(
      `${API_HOST}/api/v1/config`,
      {
        headers: { Authorization: secret },
      }
    );
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const getReferralLink = async (secret: string) => {
  try {
    const response = await axios.post(
      `${API_HOST}/api/v1/friends/reflink`,
      {},
      {
        headers: { Authorization: secret },
      }
    );
    return response.data;
  } catch (e) {
    console.error(e);
  }
};

export const getNewBonus = async (secret: string) => {
  try {
    const response = await axios.post<BonusCheckResponse>(
      `${API_HOST}/api/v1/boost/news`,
      {},
      {
        headers: { Authorization: secret },
      }
    );
    return response.data;
  } catch (e) {
    console.error(e);
  }
};
